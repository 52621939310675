<template>
  <div id="dashboard-analytics">
    <!-- {{init()}} -->
    <!-- ROW 1-->
    <div class="vx-row">
        <div v-if="activeUserInfos.privileges.find(item => item === 'caisse')" class="vx-col w-1/2 md:w-1/3 xl:w-1/5" @click="caisse()">
            <statistics-card-line
              hideChart
              class="mb-base pointer"
              icon="DollarSignIcon"
              :iconRight="true"
              statistic="Caisse"
              title=""
              statisticTitle=""/>
        </div>

        <div v-if="activeUserInfos.privileges.find(item => item === 'proforma')" class="vx-col w-1/2 md:w-1/3 xl:w-1/5" @click="proforma()">
            <statistics-card-line
              hideChart
              class="mb-base pointer"
              icon="FileTextIcon"
              :iconRight="true"
              statisticTitle=""
              title="Proformas"
              statistic="Proformas" />
        </div>

        <div v-if="activeUserInfos.privileges.find(item => item === 'commande')" class="vx-col w-1/2 md:w-1/3 xl:w-1/5" @click="commande()">
            <statistics-card-line
              hideChart
              class="mb-base pointer"
              icon="ShoppingBagIcon"
              :iconRight="true"
              statistic="Ventes"
              title=""
              statisticTitle=""/>
        </div>

        <div v-if="activeUserInfos.privileges.find(item => item === 'client')" class="vx-col w-1/2 md:w-1/3 xl:w-1/5" @click="client()">
            <statistics-card-line
              hideChart
              class="mb-base pointer"
              icon="UsersIcon"
              :iconRight="true"
              title="Clients"
              statistic="Clients"
              statisticTitle=""/>
        </div>

        <div v-if="activeUserInfos.privileges.find(item => item === 'stock')" class="vx-col w-1/2 md:w-1/3 xl:w-1/5" @click="stock()">
            <statistics-card-line
              hideChart
              class="mb-base pointer"
              icon="DatabaseIcon"
              :iconRight="true"
              title="Stock"
              statistic="Stock"
              statisticTitle=""/>
        </div>

    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card title="Encaissement du jour">
          <!-- {{commandes_hier}} -->
          <!-- {{commandes_hier_by_agence}} -->
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <vs-table :data="officines" class="w-full" stripe >
            <template slot="thead">
              <vs-th>Agence</vs-th>
              <vs-th>Montant Encaissé</vs-th>
              <vs-th>Montant Encaissé Hier</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <template v-if="data[indextr].Code">
                    <vs-avatar :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].Code" size="40px" />
                  </template>
                  <vs-avatar v-else :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].NomOffic" size="40px" />
                </vs-td>

                <vs-td>
                  {{moneyFormatter(encaissementToDay(data[indextr]._id))}}
                  <feather-icon v-if="encaissementToDay(data[indextr]._id) < encaissementPrevDay(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger"></feather-icon>
                  <feather-icon v-if="encaissementToDay(data[indextr]._id) > encaissementPrevDay(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>

                <vs-td>
                  {{moneyFormatter(encaissementPrevDay(data[indextr]._id))}}
                  <feather-icon v-if="encaissementToDay(data[indextr]._id) > encaissementPrevDay(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger">
                  </feather-icon><feather-icon v-if="encaissementToDay(data[indextr]._id) < encaissementPrevDay(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
          <e-charts autoresize :options="barEncaissement()" ref="bar" theme="ovilia-green" auto-resize />
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card title="Decaissement du jour">
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <vs-table :data="officines" class="w-full" stripe >
            <template slot="thead">
              <vs-th>Agence</vs-th>
              <vs-th>Montant Décaissé</vs-th>
              <vs-th>Montant Décaissé hier</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <template v-if="data[indextr].Code">
                    <vs-avatar :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].Code" size="40px" />
                  </template>
                  <vs-avatar v-else :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].NomOffic" size="40px" />
                </vs-td>

                <vs-td>
                  {{moneyFormatter(decaissementToDay(data[indextr]._id))}}
                  <feather-icon v-if="decaissementToDay(data[indextr]._id) < decaissementPrevDay(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger"></feather-icon>
                  <feather-icon v-if="decaissementToDay(data[indextr]._id) > decaissementPrevDay(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>

                <vs-td>
                  {{moneyFormatter(decaissementPrevDay(data[indextr]._id))}}
                  <feather-icon v-if="decaissementToDay(data[indextr]._id) > decaissementPrevDay(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger">
                  </feather-icon><feather-icon v-if="decaissementToDay(data[indextr]._id) < decaissementPrevDay(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
          <e-charts autoresize :options="barDecaissement()" ref="bar" theme="ovilia-green" auto-resize />
        </vx-card>


      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card title="Vente Jour">
          <!-- {{commandes_hier}} -->
          <!-- {{commandes_hier_by_agence}} -->
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <vs-table :data="officines" class="w-full" stripe >
            <template slot="thead">
              <vs-th>Agence</vs-th>
              <vs-th>Vente TTC</vs-th>
              <vs-th>Nbre</vs-th>
              <vs-th>Vente TTC Hier</vs-th>
              <vs-th>Nbre Hier</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <template v-if="data[indextr].Code">
                    <vs-avatar :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].Code" size="40px" />
                  </template>
                  <vs-avatar v-else :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].NomOffic" size="40px" />
                </vs-td>

                <vs-td>
                  {{moneyFormatter(venteToDayTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteToDayTTC(data[indextr]._id) < venteHierTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger"></feather-icon>
                  <feather-icon v-if="venteToDayTTC(data[indextr]._id) > venteHierTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>

                <vs-td>
                  {{nbreToDay(data[indextr]._id)}}
                </vs-td>

                <vs-td>
                  {{moneyFormatter(venteHierTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteToDayTTC(data[indextr]._id) > venteHierTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger">
                  </feather-icon><feather-icon v-if="venteToDayTTC(data[indextr]._id) < venteHierTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>
                <vs-td>
                  {{nbreHier(data[indextr]._id)}}
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
          <e-charts autoresize :options="barVenteJour()" ref="bar" theme="ovilia-green" auto-resize />
        </vx-card>


      </div>
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card title="Vente Semaine">
          <!-- {{getFirstDayOfCurrentWeek | moment("calendar", "July 10 2011") }} <br>
          {{beforeOneWeek | moment("calendar", "July 10 2011")}} <br>
          {{getFirstDayOfPreviousWeek | moment("calendar", "July 10 2011")}} <br>
          {{getLastDayOfPreviousWeek | moment("calendar", "July 10 2011")}} -->
          <!-- {{commandes_hier}} -->
          <!-- {{commandes_hier_by_agence}} -->
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <vs-table :data="officines" class="w-full" stripe >
            <template slot="thead">
              <vs-th>Agence</vs-th>
              <vs-th>Vente TTC</vs-th>
              <vs-th>Nbre</vs-th>
              <vs-th>Vente TTC Semaine précédente</vs-th>
              <vs-th>Nbre Semaine précédente</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <template v-if="data[indextr].Code">
                    <vs-avatar :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].Code" size="40px" />
                  </template>
                  <vs-avatar v-else :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].NomOffic" size="40px" />
                </vs-td>

                <vs-td>
                  {{moneyFormatter(venteCurrentWeekTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteCurrentWeekTTC(data[indextr]._id) < ventePrevWeekTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger"></feather-icon>
                  <feather-icon v-if="venteCurrentWeekTTC(data[indextr]._id) > ventePrevWeekTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>

                <vs-td>
                  {{nbreCurrentWeek(data[indextr]._id)}}
                </vs-td>

                <vs-td>
                  {{moneyFormatter(ventePrevWeekTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteCurrentWeekTTC(data[indextr]._id) > ventePrevWeekTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger">
                  </feather-icon><feather-icon v-if="venteCurrentWeekTTC(data[indextr]._id) < ventePrevWeekTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>
                <vs-td>
                  {{nbrePrevWeek(data[indextr]._id)}}
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
          <e-charts autoresize :options="barVenteWeek()" ref="bar" theme="ovilia-green" auto-resize />
        </vx-card>


      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card title="Vente Mois">
          <!-- {{commandes_hier}} -->
          <!-- {{commandes_hier_by_agence}} -->
          <!-- {{firstDayOfCurrentMonth | moment("calendar", "July 10 2011")}} <br>
          {{firstDayOfPrevMonth | moment("calendar", "July 10 2011")}}<br>
          {{beforeOneMonth | moment("calendar", "July 10 2011")}}<br>
          {{lastDayPrevMonth | moment("calendar", "July 10 2011")}} -->
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <vs-table :data="officines" class="w-full" stripe >
            <template slot="thead">
              <vs-th>Agence</vs-th>
              <vs-th>Vente TTC</vs-th>
              <vs-th>Nbre</vs-th>
              <vs-th>Vente TTC Mois précédent</vs-th>
              <vs-th>Nbre Mois précédent</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <template v-if="data[indextr].Code">
                    <vs-avatar :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].Code" size="40px" />
                  </template>
                  <vs-avatar v-else :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].NomOffic" size="40px" />
                </vs-td>

                <vs-td>
                  {{moneyFormatter(venteCurrentMonthTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteCurrentMonthTTC(data[indextr]._id) < ventePrevMonthTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger"></feather-icon>
                  <feather-icon v-if="venteCurrentMonthTTC(data[indextr]._id) > ventePrevMonthTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>

                <vs-td>
                  {{nbreCurrentMonth(data[indextr]._id)}}
                </vs-td>

                <vs-td>
                  {{moneyFormatter(ventePrevMonthTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteCurrentMonthTTC(data[indextr]._id) > ventePrevMonthTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger">
                  </feather-icon><feather-icon v-if="venteCurrentMonthTTC(data[indextr]._id) < ventePrevMonthTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>
                <vs-td>
                  {{nbrePrevMonth(data[indextr]._id)}}
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
          <e-charts autoresize :options="barVenteMonth()" ref="bar" theme="ovilia-green" auto-resize />
        </vx-card>


      </div>
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card title="Vente Année">
          <!-- {{commandes_hier}} -->
          <!-- {{commandes_hier_by_agence}} -->
          <!-- {{fisrtDayCurrentYear | moment("calendar", "July 10 2011")}} <br>
          {{firstDayPrevYear | moment("calendar", "July 10 2011")}} <br>
          {{lastDayPrevYear | moment("calendar", "July 10 2011")}} <br>
          {{beforeOneYear | moment("calendar", "July 10 2011")}} -->
          <template slot="actions">
            <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <vs-table :data="officines" class="w-full" stripe >
            <template slot="thead">
              <vs-th>Agence</vs-th>
              <vs-th>Vente TTC</vs-th>
              <vs-th>Nbre</vs-th>
              <vs-th>Vente TTC Année précédente</vs-th>
              <vs-th>Nbre Année précédente</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <template v-if="data[indextr].Code">
                    <vs-avatar :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].Code" size="40px" />
                  </template>
                  <vs-avatar v-else :color="randomColor" class="flex-shrink-0 shadow-md cursor-pointer block mr-2" :text="data[indextr].NomOffic" size="40px" />
                </vs-td>

                <vs-td>
                  {{moneyFormatter(venteCurrentYearTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteCurrentYearTTC(data[indextr]._id) < ventePrevYearTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger"></feather-icon>
                  <feather-icon v-if="venteCurrentYearTTC(data[indextr]._id) > ventePrevYearTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>

                <vs-td>
                  {{nbreCurrentYear(data[indextr]._id)}}
                </vs-td>

                <vs-td>
                  {{moneyFormatter(ventePrevYearTTC(data[indextr]._id))}}
                  <feather-icon v-if="venteCurrentYearTTC(data[indextr]._id) > ventePrevYearTTC(data[indextr]._id)" icon="ArrowDownIcon" svgClasses="w-6 h-6 text-danger">
                  </feather-icon><feather-icon v-if="venteCurrentYearTTC(data[indextr]._id) < ventePrevYearTTC(data[indextr]._id)" icon="ArrowUpIcon" svgClasses="w-6 h-6 text-primary"></feather-icon>
                </vs-td>
                <vs-td>
                  {{nbrePrevYear(data[indextr]._id)}}
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
          <e-charts autoresize :options="barVenteYear()" ref="bar" theme="ovilia-green" auto-resize />
        </vx-card>


      </div>
    </div>

  </div>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/bar'
import theme from './theme.json'

ECharts.registerTheme('ovilia-green', theme)
/* lazy loading Vue components */
const StatisticsCardLine = () => import('@/components/statistics-cards/StatisticsCardLine.vue')
const echartVenteJour = () => import('./echart_vente_jour.vue')


export default {
  data () {
    return {
      ventesJours: [],
      dateDashBoard: new Date().toISOString().split('T')[0],
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      jourFermer: false,
      colors: [
        '#8ecae6', '#219ebc', '#023047', '#ffb703', '#fb8500',
        '#e63946', '#14213d', '#003049', '#582f0e', '#283618'
      ],
      source: [['Temps', 'Aujourd\'hui', 'Hier']]

    }
  },
  components: {
    ECharts,
    StatisticsCardLine,
    echartVenteJour
  },
  computed: {
    randomColor () {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    },
    getYesterdayDate () {
      const dateDashBoard = new Date(this.dateDashBoard)
      dateDashBoard.setDate(dateDashBoard.getDate() - 1)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.jourFermer = dateDashBoard.getDay() === 0
      return dateDashBoard.toISOString().split('T')[0]
    },
    getFirstDayOfCurrentWeek () {
      const dateDashBoard = new Date(this.dateDashBoard)
      const day = dateDashBoard.getDay()
      dateDashBoard.setDate(dateDashBoard.getDate() - day + (day === 0 ? -6 : 1))
      return dateDashBoard.toISOString().split('T')[0]
    },
    beforeOneWeek () {
      const oneWeekAgo = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))
      return oneWeekAgo.toISOString().split('T')[0]
    },
    getFirstDayOfPreviousWeek () {
      const oneWeekAgo = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))
      const day = oneWeekAgo.getDay()
      const diffToMonday = oneWeekAgo.getDate() - day + (day === 0 ? -6 : 1)
      const lastMonday = new Date(oneWeekAgo)
      lastMonday.setDate(diffToMonday)
      return lastMonday.toISOString().split('T')[0]
    },
    getLastDayOfPreviousWeek () {
      const oneWeekAgo = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))
      const lastSunday = new Date(oneWeekAgo)
      lastSunday.setDate(oneWeekAgo.getDate() - 1)
      return lastSunday.toISOString().split('T')[0]
    },
    firstDayOfCurrentMonth () {
      const currentDate = new Date()
      currentDate.setDate(1)
      return currentDate.toISOString().split('T')[0]
    },

    firstDayOfPrevMonth () {
      const date = new Date()
      return new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().split('T')[0]
    },
    lastDayPrevMonth () {
      const date = new Date()
      return new Date(date.getFullYear(), date.getMonth(), 0).toISOString().split('T')[0]
    },
    beforeOneMonth () {
      const date = new Date()
      return (date.setMonth(date.getMonth() - 1))
    },
    fisrtDayCurrentYear () {
      const date = new Date()
      const year = date.getFullYear()
      return new Date(year, 0, 1).toISOString().split('T')[0]
    },
    firstDayPrevYear () {
      const date = new Date()
      const year = date.getFullYear()
      return new Date(year - 1, 0, 1).toISOString().split('T')[0]
    },
    lastDayPrevYear () {
      const date = new Date()
      const year = date.getFullYear()
      return new Date(year - 1, 12, 0).toISOString().split('T')[0]
    },
    beforeOneYear () {
      const currentDate = new Date()
      currentDate.setFullYear(currentDate.getFullYear() - 1)
      return currentDate.toISOString().split('T')[0]
    },

    officines () {
      const activeUser = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
      const activeUserOfficine = this.$store.getters['officine/getOfficineById'](activeUser.IdOfficine)
      if (activeUserOfficine && activeUserOfficine.OfficinePrincipale === true) {
        return this.$store.state.officine.officines
      } else {
        const allOfficine = this.$store.state.officine.officines
        return allOfficine.filter((o) => o._id === activeUser.IdOfficine)
      }
    }
  },
  methods: {
    caisse () {
      this.$router.push('/apps/caisse')
    },
    proforma () {
      this.$router.push('/apps/proformaclient/proforma-list')
    },
    commande () {
      this.$router.push('/apps/commandeclient/commande-list')
    },
    client () {
      this.$router.push('/apps/client/client-list')
    },
    stock () {
      this.$router.push('/apps/stock')
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    barVenteJour () {
      return {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: this.buildChartSource()
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      }
    },
    barVenteWeek () {
      return {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: this.buildChartWeekSource()
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      }
    },
    barVenteMonth () {
      return {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: this.buildChartMonthSource()
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      }
    },
    barVenteYear () {
      return {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: this.buildChartYearSource()
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      }
    },
    barEncaissement () {
      return {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: this.buildChartEncaissementSource()
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      }
    },
    barDecaissement () {
      return {
        legend: {},
        tooltip: {},
        dataset: {
          // Provide data.
          source: this.buildChartDecaissementSource()
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: 'category' },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: 'bar' }, { type: 'bar' }]
      }
    },
    buildChartSource () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const source = [['Temps', 'Aujourd\'hui', 'Hier']]
      if (this.officines) {
        this.officines.forEach((officine) => {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          source.push([(officine.Code ? officine.Code : officine.NomOffic), this.venteToDayTTC(officine._id), this.venteHierTTC(officine._id)])
        })
      }
      return source
    },
    buildChartWeekSource () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const source = [['Temps', 'Semmaine en cours', 'Semmaine précédente']]
      if (this.officines) {
        this.officines.forEach((officine) => {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          source.push([(officine.Code ? officine.Code : officine.NomOffic), this.venteCurrentWeekTTC(officine._id), this.ventePrevWeekTTC(officine._id)])
        })
      }
      return source
    },
    buildChartMonthSource () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const source = [['Temps', 'Mois en cours', 'Mois précédent']]
      if (this.officines) {
        this.officines.forEach((officine) => {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          source.push([(officine.Code ? officine.Code : officine.NomOffic), this.venteCurrentMonthTTC(officine._id), this.ventePrevMonthTTC(officine._id)])
        })
      }
      return source
    },
    buildChartYearSource () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const source = [['Temps', 'Année en cours', 'Année précédente']]
      if (this.officines) {
        this.officines.forEach((officine) => {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          source.push([(officine.Code ? officine.Code : officine.NomOffic), this.venteCurrentYearTTC(officine._id), this.ventePrevYearTTC(officine._id)])
        })
      }
      return source
    },
    buildChartEncaissementSource () {
      const source = [['Temps', 'Aujourd\'hui', 'Hier']]

      if (this.officines) {
        this.officines.forEach(officine => {
          const codeOrNom = officine.Code || officine.NomOffic
          const todayEncaissement = this.encaissementToDay(officine._id)
          const prevDayEncaissement = this.encaissementPrevDay(officine._id)

          source.push([codeOrNom, todayEncaissement, prevDayEncaissement])
        })
      }

      return source
    },
    buildChartDecaissementSource () {

      const source = [['Temps', 'Aujourd\'hui', 'Hier']]
      if (this.officines) {
        this.officines.forEach((officine) => {
    
          source.push([(officine.Code ? officine.Code : officine.NomOffic), this.decaissementToDay(officine._id), this.decaissementPrevDay(officine._id)])
        })
      }
      return source
    },
    nbreToDay (id) {
      return this.$store.getters['client/nbreToDay'](id)
    },
    nbreHier (id) {
      return this.$store.getters['client/nbreHier'](id)
    },
    nbreCurrentWeek (id) {
      return this.$store.getters['client/nbreCurrentWeek'](id)
    },
    nbrePrevWeek (id) {
      return this.$store.getters['client/nbrePrevWeek'](id)
    },
    nbreCurrentMonth (id) {
      return this.$store.getters['client/nbreCurrentMonth'](id)
    },
    nbrePrevMonth (id) {
      return this.$store.getters['client/nbrePrevMonth'](id)
    },
    nbreCurrentYear (id) {
      return this.$store.getters['client/nbreCurrentYear'](id)
    },
    nbrePrevYear (id) {
      return this.$store.getters['client/nbrePrevYear'](id)
    },
    venteToDayTTC (id) {
      return this.$store.getters['client/venteToDayTTC'](id)
    },
    venteHierTTC (id) {
      return this.$store.getters['client/venteHierTTC'](id)
    },
    venteCurrentWeekTTC (id) {
      return this.$store.getters['client/venteCurrentWeekTTC'](id)
    },
    ventePrevWeekTTC (id) {
      return this.$store.getters['client/ventePrevWeekTTC'](id)
    },
    venteCurrentMonthTTC (id) {
      return this.$store.getters['client/venteCurrentMonthTTC'](id)
    },
    ventePrevMonthTTC (id) {
      return this.$store.getters['client/ventePrevMonthTTC'](id)
    },
    venteCurrentYearTTC (id) {
      return this.$store.getters['client/venteCurrentYearTTC'](id)
    },
    ventePrevYearTTC (id) {
      return this.$store.getters['client/ventePrevYearTTC'](id)
    },
    encaissementToDay (id) {
      return this.$store.getters['operation/encaissementToDay'](id)
    },
    encaissementPrevDay (id) {
      return this.$store.getters['operation/encaissementPrevDay'](id)
    },
    decaissementToDay (id) {
      return this.$store.getters['depense/decaissementToDay'](id)
    },
    decaissementPrevDay (id) {
      return this.$store.getters['depense/decaissementPrevDay'](id)
    },
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },

    async init () {
      const activeUser = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
      const selectedOfficine = this.selectUserOfficine(activeUser.IdOfficine)

      const fetchCommandeData = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            dashDate: dateRange[0],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByDateForDash', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByDateForDash', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataPrev = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            Date: dateRange[0],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByDatePrecedent', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByDatePrecedent', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataWeek = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            fromDate: dateRange[0],
            toDate: dateRange[1],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByCurrentWeek', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByCurrentWeek', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataPrevWeek = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            fromDate: dateRange[0],
            toDate: dateRange[1],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByPrevWeek', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByPrevWeek', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataMonth = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            fromDate: dateRange[0],
            toDate: dateRange[1],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByCurrentMonth', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByCurrentMonth', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataPrevMonth = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            fromDate: dateRange[0],
            toDate: dateRange[1],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByPrevtMonth', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByPrevtMonth', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataYear = async (officine, dateRange, isComptoire = false) => {
        try {
          const payload = {
            fromDate: dateRange[0],
            toDate: dateRange[1],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByCurrentYear', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByCurrentYear', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchCommandeDataPrevYear = async (officine, dateRange, isComptoire = false) => {
        try {
          console.log(dateRange)
          const payload = {
            fromDate: dateRange[0],
            toDate: dateRange[1],
            officine: officine._id
          }

          if (isComptoire) {
            return this.$store.dispatch('commande_comptoire/getCommandeComptoireByPrevtYear', payload)
          } else {
            return this.$store.dispatch('client/getCommandeByPrevtYear', payload)
          }
        } catch (error) {
          console.log(error)
        }
      }

      const fetchOperationData = async (officine, dateRange) => {
        try {
          const payload = {
            dashDate: dateRange[0],
            officine: officine._id
          }

          return this.$store.dispatch('operation/getOperationForDashBoard', payload)
        } catch (error) {
          console.log(error)
        }
      }

      const fetchOperationDataPrevDay = async (officine, dateRange) => {
        try {
          const payload = {
            dashDate: dateRange[0],
            officine: officine._id
          }

          return this.$store.dispatch('operation/getOperationForPrevDayDashBoard', payload)
        } catch (error) {
          console.log(error)
        }
      }

      const fetchDepenseDataToDay = async (officine, dateRange) => {
        try {
          const payload = {
            dateOp: dateRange[0],
            Officine: officine._id
          }

          return this.$store.dispatch('depense/getDepenseToDayForDash', payload)
        } catch (error) {
          console.log(error)
        }
      }

      const fetchDepenseDataPrevDay = async (officine, dateRange) => {
        try {
          const payload = {
            dateOp: dateRange[0],
            Officine: officine._id
          }

          return this.$store.dispatch('depense/getDepensePrevDayForDash', payload)
        } catch (error) {
          console.log(error)
        }
      }

      if (selectedOfficine && selectedOfficine.OfficinePrincipale === true) {
        for (const officine of this.officines) {
          const dateDashBoard = this.dateDashBoard
          const dateYesterday = this.getYesterdayDate
          const firstDayOfCurrentWeek = this.getFirstDayOfCurrentWeek
          const firstDayOfPreviousWeek = this.getFirstDayOfPreviousWeek
          const beforeOneWeek = this.beforeOneWeek
          const firstDayOfCurrentMonth = this.firstDayOfCurrentMonth
          const lastDayPrevMonth = this.lastDayPrevMonth
          const firstDayPrevMonth = this.firstDayOfPrevMonth
          const fisrtDayCurrentYear = this.fisrtDayCurrentYear
          const firstDayPrevYear = this.firstDayPrevYear
          const beforeOneYear = this.beforeOneYear

          await Promise.all([
            fetchCommandeData(officine, [dateDashBoard]),
            fetchCommandeData(officine, [dateDashBoard], true),
            fetchCommandeDataPrev(officine, [dateYesterday]),
            fetchCommandeDataPrev(officine, [dateYesterday], true),
            fetchCommandeDataWeek(officine, [firstDayOfCurrentWeek, dateDashBoard]),
            fetchCommandeDataWeek(officine, [firstDayOfCurrentWeek, dateDashBoard], true),
            fetchCommandeDataPrevWeek(officine, [firstDayOfPreviousWeek, beforeOneWeek]),
            fetchCommandeDataPrevWeek(officine, [firstDayOfPreviousWeek, beforeOneWeek], true),
            fetchCommandeDataMonth(officine, [firstDayOfCurrentMonth, dateDashBoard]),
            fetchCommandeDataMonth(officine, [firstDayOfCurrentMonth, dateDashBoard], true),
            fetchCommandeDataPrevMonth(officine, [firstDayPrevMonth, lastDayPrevMonth]),
            fetchCommandeDataPrevMonth(officine, [firstDayPrevMonth, lastDayPrevMonth], true),
            fetchCommandeDataYear(officine, [fisrtDayCurrentYear, dateDashBoard]),
            fetchCommandeDataYear(officine, [fisrtDayCurrentYear, dateDashBoard], true),
            fetchCommandeDataPrevYear(officine, [firstDayPrevYear, beforeOneYear]),
            fetchCommandeDataPrevYear(officine, [firstDayPrevYear, beforeOneYear], true),
            fetchOperationData(officine, [dateDashBoard]),
            fetchOperationDataPrevDay(officine, [dateYesterday]),
            fetchDepenseDataToDay(officine, [dateDashBoard]),
            fetchDepenseDataPrevDay(officine, [dateYesterday])
          ])
        }
      } else if (selectedOfficine) {
        const dateDashBoard = this.dateDashBoard
        const dateYesterday = this.getYesterdayDate
        const firstDayOfCurrentWeek = this.getFirstDayOfCurrentWeek
        const firstDayOfPreviousWeek = this.getFirstDayOfPreviousWeek
        const beforeOneWeek = this.beforeOneWeek
        const firstDayOfCurrentMonth = this.firstDayOfCurrentMonth
        const lastDayPrevMonth = this.lastDayPrevMonth
        const firstDayPrevMonth = this.firstDayOfPrevMonth
        const fisrtDayCurrentYear = this.fisrtDayCurrentYear
        const firstDayPrevYear = this.firstDayPrevYear
        const beforeOneYear = this.beforeOneYear

        await Promise.all([
          fetchCommandeData(selectedOfficine, [dateDashBoard]),
          fetchCommandeDataPrev(selectedOfficine, [dateYesterday]),
          fetchCommandeDataWeek(selectedOfficine, [firstDayOfCurrentWeek, dateDashBoard]),
          fetchCommandeDataPrevWeek(selectedOfficine, [firstDayOfPreviousWeek, beforeOneWeek]),
          fetchCommandeDataMonth(selectedOfficine, [firstDayOfCurrentMonth, dateDashBoard]),
          fetchCommandeDataPrevMonth(selectedOfficine, [firstDayPrevMonth, lastDayPrevMonth]),
          fetchCommandeDataYear(selectedOfficine, [fisrtDayCurrentYear, dateDashBoard]),
          fetchCommandeDataPrevYear(selectedOfficine, [firstDayPrevYear, beforeOneYear]),
          fetchOperationData(selectedOfficine, [dateDashBoard]),
          fetchOperationDataPrevDay(selectedOfficine, [dateYesterday]),
          fetchDepenseDataToDay(selectedOfficine, [dateDashBoard]),
          fetchDepenseDataPrevDay(selectedOfficine, [dateYesterday])
        ])
      }
    },

    groupBy (tableauObjets, propriete) {
      return tableauObjets.reduce((acc, obj) => {
        const cle = obj[propriete]
        if (!acc[cle]) {
          acc[cle] = []
        }
        acc[cle].push(obj)
        return acc
      }, {})
    },
    convertObjectToArray (obj) {
      return Object.keys(obj).map((key) => {
        return obj[key]
      })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchUsers () {
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  mounted () {
    this.init()
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.userManagement.users.length > 0)) this.fetchUsers()
    this.init()
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}

.pointer{
  cursor: pointer;
}
/*! rtl:end:ignore */
</style>
